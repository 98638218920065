import React, { useState, useRef, useEffect } from "react"
import styled, { Box, useColorMode } from "@xstyled/styled-components"
import compareDesc from "date-fns/compareDesc"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { useIdle } from "react-use"
import ReactGA from "react-ga"
import { startOfDay, setISODay, addWeeks } from "date-fns"

import Layout from "../components/layout"
import SEO from "../components/atoms/Seo"
import PageWrap from "../components/atoms/PageWrap"
import Countdown from "../components/atoms/Countdown"

import VideoPlayer from "../components/molecules/VideoPlayer"
import AudioPlayer from "../components/molecules/AudioPlayer"
import Gallery from "../components/molecules/Gallery"
import Poem from "../components/molecules/Poem"
import Supporters from "../components/molecules/Supporters"

import { getCurrentDayData } from "../utils/data"
import theme from "../utils/theme"
import Header from "../components/molecules/Header"
import { getUTC } from "../utils/date"

const Container = styled.main`
  padding-top: 6;
  overflow: hidden;
`

const Italic = styled.box`
  font-style: italic;
`

const Curator = styled(Italic)`
  cursor: pointer;
  border-bottom: 1px solid;
  border-color: transparent;
  display: inline-block;
  transition: 0.2s;
  border-color: dark;

  &:hover {
    border-color: transparent;
  }
`

const Modal = styled.div`
  padding: 4;
  position: fixed;
  z-index: over;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  transition: opacity 0.4s;
`

const IdleFade = styled.div`
  opacity: ${props => (props.isIdle ? 0 : 1)};
  transition: opacity 1s;
`

const INTERVAL_CHECK = 7000

const Content = ({ currentSlot }) => {
  switch (currentSlot.type) {
    case "video":
      const poster = currentSlot.primary.video_poster
        ? currentSlot.primary.video_poster.url
        : ""

      return (
        <PageWrap>
          <Box my={6}>
            <VideoPlayer
              poster={poster}
              src={currentSlot.primary.video_link.url}
            />
          </Box>
        </PageWrap>
      )
    case "audio":
      return (
        <PageWrap>
          <Box my={6}>
            <AudioPlayer src={currentSlot.primary.audio_link.url} />
          </Box>
        </PageWrap>
      )
    case "video1":
      return <Gallery images={currentSlot.fields} />
    case "poem":
      return (
        <Poem
          title={currentSlot.primary.poem_title}
          content={currentSlot.primary.poem}
          image={currentSlot.primary.poem_image}
        />
      )
    default:
      return false
  }
}

const IndexPage = ({ data }) => {
  const isIdle = useIdle(theme.idleTime)
  const hasMounted = useRef(false)
  const [currentSlot, setCurrentSlot] = useState(null)
  const [modalOpen, setModal] = useState(false)
  const general = data.prismic.general.edges[0].node
  const today = getUTC()
  const mondayData = data.prismic.monday.edges
  const mondayFirstSlot =
    mondayData &&
    mondayData.length > 0 &&
    mondayData[0].node.body &&
    mondayData[0].node.body.length > 0 &&
    mondayData[0].node.body[0].primary
  const mondayDate = startOfDay(setISODay(addWeeks(Date.now(), 1), 1))
  const toggleModal = () => {
    if (!modalOpen) {
      const title =
        (currentSlot &&
          currentSlot.primary &&
          RichText.asText(currentSlot.primary.title)) ||
        "curator"

      ReactGA.event({
        category: "Open Description Modal",
        action: `User clicked link to view ${title} description`,
      })
    }

    setModal(!modalOpen)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlot(getCurrentDayData(data.prismic))
    }, INTERVAL_CHECK)

    setCurrentSlot(getCurrentDayData(data.prismic))

    hasMounted.current = true

    return () => {
      clearInterval(interval)
    }
  }, [data.prismic, currentSlot])

  return (
    <Layout>
      <SEO lang="en" title="Compulsory Gallery" />
      <Header data={data} />
      <Container>
        <PageWrap>
          <Modal isOpen={modalOpen} onClick={toggleModal}>
            {currentSlot && (
              <Box row justifyContent="center">
                <Box col={{ xs: 1, md: 1 / 2 }}>
                  {currentSlot.primary.curator && (
                    <Box mb={5} fontSize={8} style={{ color: "white" }}>
                      Curated by {RichText.asText(currentSlot.primary.curator)}
                    </Box>
                  )}
                  {currentSlot.primary.description && (
                    <Italic
                      fontSize={4}
                      lineHeight={1.35}
                      style={{ color: "white" }}
                    >
                      {RichText.asText(currentSlot.primary.description)}
                    </Italic>
                  )}
                </Box>
              </Box>
            )}
          </Modal>
        </PageWrap>
        {hasMounted.current &&
          !currentSlot &&
          (today.day === "saturday" || today.day === "sunday") && (
            <PageWrap>
              <Box mt={16} mb={20} textAlign="center">
                <Italic as="h3" fontSize={9} mb={3}>
                  Next event
                </Italic>
                <Box as="h4" fontSize={14}>
                  <Countdown end={mondayDate} />
                </Box>
              </Box>
            </PageWrap>
          )}

        {currentSlot && (
          <>
            <Content currentSlot={currentSlot} />
            <IdleFade isIdle={isIdle}>
              <PageWrap>
                <Box row mt={14} mb={10}>
                  <Box
                    col={{ xs: 1, md: 1 / 2 }}
                    textAlign={{ xs: "center", md: "left" }}
                  >
                    {currentSlot && currentSlot.primary.curator && (
                      <Curator
                        onClick={toggleModal}
                        as="h2"
                        fontSize={{ xs: 4, md: 6 }}
                        fontWeight="normal"
                      >
                        Curated by{" "}
                        {RichText.asText(currentSlot.primary.curator)}
                      </Curator>
                    )}
                  </Box>
                  <Box
                    mt={{ xs: 2, md: 0 }}
                    col={{ xs: 1, md: 1 / 2 }}
                    textAlign={{ xs: "center", md: "right" }}
                  >
                    {currentSlot && currentSlot.primary.title && (
                      <Box as="h2" fontSize={{ xs: 4, md: 6 }}>
                        '{RichText.asText(currentSlot.primary.title)}'
                      </Box>
                    )}
                    {currentSlot && currentSlot.primary.author && (
                      <Italic mt="5px" as="h3" fontSize={3} fontStyle="italic">
                        {RichText.asText(currentSlot.primary.author)}
                      </Italic>
                    )}
                  </Box>
                </Box>
              </PageWrap>
            </IdleFade>
          </>
        )}
        <IdleFade isIdle={isIdle}>
          <Supporters supporters={general.supporters} />
        </IdleFade>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      general: allGenerals {
        edges {
          node {
            supporters {
              supporter_image
              supporter_link {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              supporter_name
            }
          }
        }
      }
      monday: allMondays {
        edges {
          node {
            body {
              ... on PRISMIC_MondayBodyPoem {
                type
                primary {
                  curator
                  author
                  category
                  description
                  poem
                  poem_title
                  slot_timetable
                  title
                  poem_image
                }
              }
              ... on PRISMIC_MondayBodyAudio {
                type
                primary {
                  audio_link {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  author
                  category
                  curator
                  description
                  slot_timetable
                  title
                }
              }
              ... on PRISMIC_MondayBodyVideo1 {
                type
                fields {
                  alternative_text
                  image
                }
                primary {
                  author
                  category
                  curator
                  description
                  slot_timetable
                  title
                }
              }
              ... on PRISMIC_MondayBodyVideo {
                type
                primary {
                  author
                  category
                  title
                  curator
                  description
                  slot_timetable
                  video_poster
                  video_link {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
      tuesday: allTuesdays {
        edges {
          node {
            body {
              ... on PRISMIC_TuesdayBodyPoem {
                type
                primary {
                  curator
                  author
                  category
                  description
                  poem
                  poem_title
                  slot_timetable
                  title
                  poem_image
                }
              }
              ... on PRISMIC_TuesdayBodyAudio {
                type
                primary {
                  audio_link {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  author
                  category
                  curator
                  description
                  slot_timetable
                  title
                }
              }
              ... on PRISMIC_TuesdayBodyVideo1 {
                type
                fields {
                  alternative_text
                  image
                }
                primary {
                  author
                  category
                  curator
                  description
                  slot_timetable
                  title
                }
              }
              ... on PRISMIC_TuesdayBodyVideo {
                type
                primary {
                  author
                  category
                  title
                  curator
                  description
                  slot_timetable
                  video_poster
                  video_link {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
      wednesday: allWednesdays {
        edges {
          node {
            body {
              ... on PRISMIC_WednesdayBodyPoem {
                type
                primary {
                  curator
                  author
                  category
                  description
                  poem
                  poem_title
                  slot_timetable
                  title
                  poem_image
                }
              }
              ... on PRISMIC_WednesdayBodyAudio {
                type
                primary {
                  audio_link {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  author
                  category
                  curator
                  description
                  slot_timetable
                  title
                }
              }
              ... on PRISMIC_WednesdayBodyVideo1 {
                type
                fields {
                  alternative_text
                  image
                }
                primary {
                  author
                  category
                  curator
                  description
                  slot_timetable
                  title
                }
              }
              ... on PRISMIC_WednesdayBodyVideo {
                type
                primary {
                  author
                  category
                  title
                  curator
                  description
                  slot_timetable
                  video_poster
                  video_link {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
      thursday: allThursdays {
        edges {
          node {
            body {
              ... on PRISMIC_ThursdayBodyPoem {
                type
                primary {
                  curator
                  author
                  category
                  description
                  poem
                  poem_title
                  slot_timetable
                  title
                  poem_image
                }
              }
              ... on PRISMIC_ThursdayBodyAudio {
                type
                primary {
                  audio_link {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  author
                  category
                  curator
                  description
                  slot_timetable
                  title
                }
              }
              ... on PRISMIC_ThursdayBodyVideo1 {
                type
                fields {
                  alternative_text
                  image
                }
                primary {
                  author
                  category
                  curator
                  description
                  slot_timetable
                  title
                }
              }
              ... on PRISMIC_ThursdayBodyVideo {
                type
                primary {
                  author
                  category
                  title
                  curator
                  description
                  slot_timetable
                  video_poster
                  video_link {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
      friday: allFridays {
        edges {
          node {
            body {
              ... on PRISMIC_FridayBodyPoem {
                type
                primary {
                  curator
                  author
                  category
                  description
                  poem
                  poem_title
                  slot_timetable
                  title
                  poem_image
                }
              }
              ... on PRISMIC_FridayBodyAudio {
                type
                primary {
                  audio_link {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  author
                  category
                  curator
                  description
                  slot_timetable
                  title
                }
              }
              ... on PRISMIC_FridayBodyVideo1 {
                type
                fields {
                  alternative_text
                  image
                }
                primary {
                  author
                  category
                  curator
                  description
                  slot_timetable
                  title
                }
              }
              ... on PRISMIC_FridayBodyVideo {
                type
                primary {
                  author
                  category
                  title
                  curator
                  description
                  slot_timetable
                  video_poster
                  video_link {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
      saturday: allSaturdays {
        edges {
          node {
            body {
              ... on PRISMIC_SaturdayBodyPoem {
                type
                primary {
                  curator
                  author
                  category
                  description
                  poem
                  poem_title
                  slot_timetable
                  title
                  poem_image
                }
              }
              ... on PRISMIC_SaturdayBodyAudio {
                type
                primary {
                  audio_link {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  author
                  category
                  curator
                  description
                  slot_timetable
                  title
                }
              }
              ... on PRISMIC_SaturdayBodyVideo1 {
                type
                fields {
                  alternative_text
                  image
                }
                primary {
                  author
                  category
                  curator
                  description
                  slot_timetable
                  title
                }
              }
              ... on PRISMIC_SaturdayBodyVideo {
                type
                primary {
                  author
                  category
                  title
                  curator
                  description
                  slot_timetable
                  video_poster
                  video_link {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
      sunday: allSundays {
        edges {
          node {
            body {
              ... on PRISMIC_SundayBodyPoem {
                type
                primary {
                  curator
                  author
                  category
                  description
                  poem
                  poem_title
                  slot_timetable
                  title
                  poem_image
                }
              }
              ... on PRISMIC_SundayBodyAudio {
                type
                primary {
                  audio_link {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  author
                  category
                  curator
                  description
                  slot_timetable
                  title
                }
              }
              ... on PRISMIC_SundayBodyVideo1 {
                type
                fields {
                  alternative_text
                  image
                }
                primary {
                  author
                  category
                  curator
                  description
                  slot_timetable
                  title
                }
              }
              ... on PRISMIC_SundayBodyVideo {
                type
                primary {
                  author
                  category
                  title
                  curator
                  description
                  slot_timetable
                  video_poster
                  video_link {
                    ... on PRISMIC__FileLink {
                      url
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
