import React, { useState, useRef, useEffect } from "react"
import styled, { breakpoints, css } from "@xstyled/styled-components"
import { motion } from "framer-motion"
import { RichText } from "prismic-reactjs"

import PageWrap from "../../atoms/PageWrap"

const MAXHEIGHT = "90vh"
const MOBILEMAXHEIGHT = "500px"

const Container = styled.div(
  breakpoints({
    xs: css`
      position: relative;
      margin-bottom: 10;
      padding: 6 0;

      height: ${MOBILEMAXHEIGHT};
    `,
    md: css`
      height: ${MAXHEIGHT};
    `,
  })
)

const Inner = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  display: flex;
`

const Item = styled.div(
  breakpoints({
    xs: css`
      width: calc(100vw - 40px);
      height: ${MOBILEMAXHEIGHT};
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2;
    `,
    md: css`
      width: calc(100vw - 80px);
      height: ${MAXHEIGHT};
      width: ${p => p.w}px;
    `,
  })
)

const Img = styled.img`
  pointer-events: none;
  max-height: 100%;
  max-width: 100%;
`

const Left = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  cursor: w-resize;
`
const Right = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  cursor: e-resize;
`

const spring = {
  type: "spring",
  damping: 50,
  stiffness: 200,
}

const Gallery = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [width, setWidth] = useState(300)
  const containerRef = useRef(null)
  // const { width, height } = useWindowSize()

  const triggerPrev = () => {
    const nextIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1
    setCurrentIndex(nextIndex)
  }

  const triggerNext = () => {
    const nextIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1
    setCurrentIndex(nextIndex)
  }

  useEffect(() => {
    setWidth(containerRef.current.offsetWidth)
  }, [containerRef])

  return (
    <PageWrap>
      <Container ref={containerRef}>
        <Inner animate={{ x: -width * currentIndex }} transition={spring}>
          {images.map((img, index) => {
            const alt = img.alternative_text
              ? RichText.asText(img.alternative_text)
              : "Compulsory Gallery"
            const url = img.image.url
            return (
              <Item key={url} w={width}>
                <Img src={url} alt={alt} />
              </Item>
            )
          })}
        </Inner>
        {images.length > 1 && (
          <>
            <Left onClick={triggerPrev} />
            <Right onClick={triggerNext} />
          </>
        )}
      </Container>
    </PageWrap>
  )
}

export default Gallery
