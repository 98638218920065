import React, { useEffect } from "react"
import { getUTC } from "../../../utils/date"

const Clock = () => {
  const cx = 100
  const cy = 100

  const shifter = val => {
    return [val, cx, cy].join(" ")
  }

  useEffect(() => {
    const hands = []
    const date = getUTC()
    hands.push(document.querySelector("#secondhand > *"))
    hands.push(document.querySelector("#minutehand > *"))
    hands.push(document.querySelector("#hourhand > *"))

    const hoursAngle = (360 * date.hour) / 12 + date.minute / 2
    const minuteAngle = (360 * date.minute) / 60
    const secAngle = (360 * date.second) / 60

    hands[0].setAttribute("from", shifter(secAngle))
    hands[0].setAttribute("to", shifter(secAngle + 360))
    hands[1].setAttribute("from", shifter(minuteAngle))
    hands[1].setAttribute("to", shifter(minuteAngle + 360))
    hands[2].setAttribute("from", shifter(hoursAngle))
    hands[2].setAttribute("to", shifter(hoursAngle + 360))
  }, [])

  return (
    <svg width="200" height="200">
      <g>
        <circle
          id="circle"
          strokeWidth="14px"
          stroke="currentColor"
          fill="none"
          cx="100"
          cy="100"
          r="80"
        ></circle>
      </g>
      <g>
        <line
          x1="100"
          y1="100"
          x2="100"
          y2="55"
          transform="rotate(80 100 100)"
          strokeWidth="8px"
          stroke="currentColor"
          id="hourhand"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="43200s"
            repeatCount="indefinite"
          />
        </line>
        <line
          x1="100"
          y1="100"
          x2="100"
          y2="40"
          strokeWidth="8px"
          stroke="currentColor"
          id="minutehand"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="3600s"
            repeatCount="indefinite"
          />
        </line>
        <line
          x1="100"
          y1="100"
          x2="100"
          y2="30"
          strokeWidth="5px"
          stroke="currentColor"
          id="secondhand"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="60s"
            repeatCount="indefinite"
          />
        </line>
      </g>
      <circle
        id="center"
        strokeWidth="5px"
        stroke="currentColor"
        fill="currentColor"
        cx="100"
        cy="100"
        r="3"
      ></circle>
    </svg>
  )
}

export default Clock
