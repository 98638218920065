import React, { useEffect, useState } from "react"

import { getUTC } from "../../../utils/date"

const Time = () => {
  const [time, setTime] = useState(null)

  useEffect(() => {
    const interval = setInterval(() => {
      const h = getUTC().hour === 24 ? "00" : getUTC().hour
      setTime({
        hour: h,
        minute:
          getUTC().minute.toString().length > 1
            ? getUTC().minute
            : `0${getUTC().minute}`,
        second:
          getUTC().second.toString().length > 1
            ? getUTC().second
            : `0${getUTC().second}`,
      })
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [time])

  return <>{time && `${time.hour}:${time.minute}:${time.second}`}</>
}

export default Time
