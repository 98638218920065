import React, { useState, useRef, useEffect } from "react"
import ReactPlayer from "react-player"
import PropTypes from "prop-types"
import styled, { css } from "@xstyled/styled-components"
import InputRange from "react-input-range"
import { useIdle, useHoverDirty } from "react-use"
import screenfull from "screenfull"

import { formatSeconds } from "../../../utils/date"

import playIcon from "../../../images/icons/play.svg"
import playIconRounded from "../../../images/icons/play-t.svg"
import pauseIcon from "../../../images/icons/pause.svg"
import fullscreenIcon from "../../../images/icons/fullscreen.svg"
import volumeIcon from "../../../images/icons/volume.svg"

const Container = styled.div`
  position: relative;
  color: white;
`

const Controls = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  padding: 0 4 0 4;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: 0.3s;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));

  height: 40px;
  font-size: 11px;
`

const PlayButton = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Icon = styled.img`
  width: 14px;
  height: 14px;
`

const RangeContainer = styled.div`
  width: 100%;
  padding: 0 4;
`

const PlayIcon = styled.img`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  cursor: pointer;
`

const TimeContainer = styled.div`
  position: relative;
`

const Time = styled.div`
  font-family: "helvetica", "arial", "sans-serif";
  opacity: ${props => (props.isHidden ? 0 : 1)};
  transition: 0.2s;
`

const VolumeContainer = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  margin-left: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Volume = styled.div`
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};
  transition: 0.2s;
`

const Fullscreen = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 3px;
  cursor: pointer;
`

const Poster = styled.div`
  background-image: url(${props => props.poster});
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const VideoContainer = styled.div`
  position: ${props => (props.isFullScreen ? "absolute" : "static")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  /* & > div {
    height: ${props => (props.isFullScreen ? "100%" : "auto")};
  } */
`

let hasPlayed = false
const VideoPlayer = ({ src, width, height, playing, controls, poster }) => {
  const playerRef = useRef(null)
  const controlsRef = useRef(null)
  const containerRef = useRef(null)
  const volumeContainerRef = useRef(null)
  const volumeRef = useRef(null)
  const [isFullScreen, setFullScreen] = useState(false)
  const [isPlaying, setPlayingState] = useState(playing)
  const [seekbarPos, setSeekbarPos] = useState(0)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [volume, setVolume] = useState(1)
  const isIdle = useIdle(2e3)
  const isHovering = useHoverDirty(containerRef)
  const isVolumeHover = useHoverDirty(volumeContainerRef)
  const isVolumeIconHover = useHoverDirty(volumeRef)

  const togglePlayingState = () => {
    setPlayingState(!isPlaying)
    if (isPlaying) hasPlayed = true
  }

  const handleVideoProgress = ({ played }) => {
    setSeekbarPos(played * 100)
    setCurrentTime(formatSeconds(playerRef.current.getCurrentTime()))
  }

  const handleSeekbarUpdate = value => {
    playerRef.current.seekTo(value / 100)
    setSeekbarPos(value)
  }

  const handleVolumeUpdate = value => {
    setVolume(value / 100)
  }

  const handleVideoReady = () => {
    setDuration(formatSeconds(playerRef.current.getDuration()))
  }

  const handleVideoEnded = () => {
    togglePlayingState()
  }

  const handleFullScreen = () => {
    if (screenfull.isEnabled) {
      if (screenfull.isFullscreen) {
        setFullScreen(false)
      } else {
        setFullScreen(true)
      }

      screenfull.toggle(containerRef.current)
    }
  }

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.onchange(() => {
        setFullScreen(screenfull.isFullscreen)
      })
    }
  }, [])

  return (
    <Container ref={containerRef}>
      <VideoContainer onClick={togglePlayingState} isFullScreen={isFullScreen}>
        {!isPlaying && <PlayIcon src={playIconRounded} alt="play video" />}
        {!isPlaying && !hasPlayed && poster && <Poster poster={poster} />}
        <ReactPlayer
          ref={playerRef}
          style={{
            display: "flex",
          }}
          playing={isPlaying}
          url={src}
          width={width}
          height={height}
          progressInterval={50}
          volume={volume}
          onProgress={handleVideoProgress}
          onReady={handleVideoReady}
          onEnded={handleVideoEnded}
        />
      </VideoContainer>
      {controls && (
        <Controls ref={controlsRef} isVisible={!isIdle && isHovering}>
          <PlayButton onClick={togglePlayingState}>
            {isPlaying ? (
              <Icon src={pauseIcon} alt="Pause video" />
            ) : (
              <Icon src={playIcon} alt="Play video" />
            )}
          </PlayButton>
          <RangeContainer>
            <InputRange
              minValue={0}
              maxValue={100}
              value={seekbarPos}
              onChange={handleSeekbarUpdate}
              formatLabel={v => ``}
            />
          </RangeContainer>
          <TimeContainer>
            <Volume
              ref={volumeRef}
              isVisible={isVolumeHover || isVolumeIconHover}
            >
              <InputRange
                minValue={0}
                maxValue={100}
                value={volume * 100}
                onChange={handleVolumeUpdate}
                formatLabel={() => ``}
              />
            </Volume>
            <Time isHidden={isVolumeHover || isVolumeIconHover}>
              {currentTime}/{duration}
            </Time>
          </TimeContainer>
          <VolumeContainer ref={volumeContainerRef}>
            <Icon src={volumeIcon} alt="volume" />
          </VolumeContainer>
          <Fullscreen
            src={fullscreenIcon}
            alt="fullscreen"
            onClick={handleFullScreen}
          />
        </Controls>
      )}
    </Container>
  )
}

export default VideoPlayer

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  playing: PropTypes.bool,
  controls: PropTypes.bool,
}

VideoPlayer.defaultProps = {
  width: "100%",
  height: "100%",
  playing: false,
  controls: true,
}
