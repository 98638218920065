import { days, getUTC } from "./date"

export const getCurrentDayData = data => {
  if (!data) return null
  const now = getUTC()
  const t = data[now.day]
  const today = t ? t.edges[0].node.body[0] : null

  return today
}

const isSafari =
  typeof navigator !== "undefined"
    ? /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    : false

var userAgent =
    typeof window !== "undefined"
      ? window.navigator.userAgent.toLowerCase()
      : false,
  safari = /safari/.test(userAgent),
  ios = /iphone|ipod|ipad/.test(userAgent)
