import React from "react"
import { RichText } from "prismic-reactjs"
import styled from "@xstyled/styled-components"
import PageWrap from "../../atoms/PageWrap"

import { linkResolver } from "../../../../prismic-configuration"

const Container = styled.div`
  text-align: center;
  padding: 10 0;
`
const Title = styled.h3`
  font-size: 6;
  font-weight: 600;
  margin-bottom: 2;
`
const Content = styled.div`
  font-style: italic;
  line-height: 1.4;
`

const Image = styled.img`
  max-width: 300px;
  width: 100%;
  margin: 10 auto 0 auto;
`

const Poem = ({ title, content, image }) => {
  return (
    <PageWrap>
      <Container>
        <Title>{RichText.asText(title)}</Title>
        <Content>{RichText.render(content, linkResolver)}</Content>
        {image && image.url && (
          <Image src={image.url} alt={RichText.asText(title)} />
        )}
      </Container>
    </PageWrap>
  )
}

export default Poem
