import React, { useEffect, useState } from "react"

const addPrefix = number =>
  number.toString().length > 1 ? number : `0${number}`

const Countdown = ({ end }) => {
  const [count, setCount] = useState(null)
  // const end = new Date("03/28/2020 3:45 PM")
  const _second = 1000
  const _minute = _second * 60
  const _hour = _minute * 60
  const _day = _hour * 24

  const updateTime = () => {
    const isoNow = new Date().toISOString()
    const now = new Date(isoNow)
    const distance = end - now

    if (distance <= 0) {
      setCount({
        days: 0,
        hours: addPrefix(0),
        minutes: addPrefix(0),
        seconds: addPrefix(0),
      })
      return
    }

    const days = Math.floor(distance / _day)
    const hours = Math.floor((distance % _day) / _hour)
    const minutes = Math.floor((distance % _hour) / _minute)
    const seconds = Math.floor((distance % _minute) / _second)

    setCount({
      days,
      hours: addPrefix(hours),
      minutes: addPrefix(minutes),
      seconds: addPrefix(seconds),
    })
  }

  useEffect(() => {
    updateTime()
    const timer = setInterval(updateTime, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <>
      {count && (
        <>
          {count.days > 0 && (
            <>
              {count.days}
              <small>d</small>
            </>
          )}{" "}
          {count.hours}
          <small>h</small> {count.minutes}
          <small>m</small> {count.seconds}
          <small>s</small>
        </>
      )}
    </>
  )
}

export default Countdown
