import React from "react"
import styled, {
  breakpoints,
  css,
  Box,
  useColorMode,
  useTheme,
  variant,
} from "@xstyled/styled-components"
import { RichText } from "prismic-reactjs"
import { useIdle } from "react-use"

import PageWrap from "../../atoms/PageWrap"
import Clock from "../../atoms/Clock"
import Time from "../../atoms/Time"
import InfosIcon from "../../atoms/InfosIcon"

import logo from "../../../images/holding-logo.png"
import logoLight from "../../../images/holding-logo--light.png"

import { getCurrentDayData } from "../../../utils/data"

const StyledHeader = styled.header`
  position: relative;
  padding: 4 0;
  opacity: ${props => (props.isIdle ? 0 : 1)};
  transition: opacity 1s;
`

const Container = styled.div(
  breakpoints({
    xs: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2;
    `,
    md: css`
      align-items: center;
      margin-top: 0;
    `,
  })
)

const H1 = styled.h1(
  breakpoints({
    xs: css`
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 30px;
    `,
    md: css`
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `,
  })
)

const Logo = styled.img`
  width: 380px;
`

const TimeContainer = styled.div`
  position: absolute;
  top: 17px;
  left: 49px;
  font-size: 4;
  opacity: 0;
  transition: opacity 0.2s;
`

const ClockContainer = styled.div`
  position: relative;
  width: 60px;
  height: 60px;

  svg {
    transform: translate(-85px, -70px) scale(0.15);
  }

  &:hover ${TimeContainer} {
    opacity: 1;
  }
`

const Italic = styled.span`
  font-style: italic;
`

const ScheduleDropdown = styled.div(
  breakpoints({
    xs: css`
      position: absolute;
      z-index: 100;
      padding: 0 2;
      top: 100%;
      width: 200px;
      text-align: center;
      font-size: 2;
      opacity: 0;
      visibility: hidden;
      transform: none;
      text-align: right;
      left: auto;
      right: -20px;
      transition: all 0.2s;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0%;
        right: 0;
        bottom: 0;
        background-color: light;
        opacity: 0.5;
        z-index: -1;
      }
    `,
    xl: css`
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
    `,
  })
)

const SchedWrap = styled.div`
  display: flex;
`

const ScheduleContainer = styled.div`
  position: relative;
  margin-left: 2;

  &:hover {
    opacity: 1 !important;
  }

  &:hover ${ScheduleDropdown} {
    opacity: 1;
    visibility: visible;
  }

  svg {
    display: block;
  }
`

const ScheduleSlot = styled.div`
  padding: 2 0;
`

const Sched = ({ todayData }) => {
  return (
    <ScheduleContainer>
      <InfosIcon width={26} height={26} />
      <ScheduleDropdown>
        {todayData ? (
          <ScheduleSlot key={todayData.primary.title}>
            {todayData.primary.slot_timetable && (
              <Box fontWeight="600">
                {RichText.asText(todayData.primary.slot_timetable)}
              </Box>
            )}

            {todayData.primary.title && todayData.primary.category && (
              <Box mt={1}>
                '{RichText.asText(todayData.primary.title)}' -{" "}
                <Italic>{RichText.asText(todayData.primary.category)}</Italic>
              </Box>
            )}
          </ScheduleSlot>
        ) : (
          <ScheduleSlot>
            <Box>No event today</Box>
          </ScheduleSlot>
        )}
      </ScheduleDropdown>
    </ScheduleContainer>
  )
}

const Header = ({ data }) => {
  const theme = useTheme()
  const isIdle = useIdle(theme.idleTime)
  const [colorMode, setColorMode] = useColorMode()
  const todayData = getCurrentDayData(data.prismic)

  return (
    <StyledHeader isIdle={isIdle}>
      <PageWrap overflow="initial">
        <Container>
          <ClockContainer>
            <Clock />
            <TimeContainer>
              <Time />
            </TimeContainer>
          </ClockContainer>
          <H1>
            {colorMode === "dark" ? (
              <Logo src={logoLight} alt="Compulsory Gallery" />
            ) : (
              <Logo src={logo} alt="Compulsory Gallery" />
            )}
          </H1>
          {todayData && (
            <Box display="flex" alignItems="center">
              <SchedWrap>
                <Sched todayData={todayData} />
              </SchedWrap>
            </Box>
          )}
        </Container>
      </PageWrap>
    </StyledHeader>
  )
}

export default Header
