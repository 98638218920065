import React from "react"
import styled from "@xstyled/styled-components"

const StyledPageWrap = styled.box`
  max-width: 1100px;
  margin: 0 auto;
  /* overflow: hidden; */
`

const PageWrap = ({ children, ...props }) => {
  return (
    <StyledPageWrap px={{ xs: 2, md: 3 }} {...props}>
      {children}
    </StyledPageWrap>
  )
}

export default PageWrap
