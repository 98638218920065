import React, { useState, useRef } from "react"
import ReactPlayer from "react-player"
import PropTypes from "prop-types"
import styled, { useColorMode } from "@xstyled/styled-components"
import InputRange from "react-input-range"
import { useHoverDirty } from "react-use"

import { formatSeconds } from "../../../utils/date"

import playIcon from "../../../images/icons/play--black.svg"
import pauseIcon from "../../../images/icons/pause--black.svg"
import volumeIcon from "../../../images/icons/volume--black.svg"

// import soundTest from "../../../audios/row-2-commentaire.mp3"

const Container = styled.div`
  position: relative;
  color: black;
  margin: 10 0;

  .input-range__track {
    background: rgba(0, 0, 0, 0.1);
  }

  .input-range__track--active {
    background: black;
  }

  ${p => (p.isDark ? `filter: invert(1)` : "")}
`

const Controls = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  padding: 0 4 0 4;
  opacity: 1;
  transition: 0.3s;
  background-color: white;

  height: 40px;
  font-size: 11px;
`

const PlayButton = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Icon = styled.img`
  width: 14px;
  height: 14px;
`

const RangeContainer = styled.div`
  width: 100%;
  padding: 0 4;
`

const TimeContainer = styled.div`
  position: relative;
`

const Time = styled.div`
  font-family: "helvetica", "arial", "sans-serif";
  opacity: ${props => (props.isHidden ? 0 : 1)};
  transition: 0.2s;
`

const VolumeContainer = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  margin-left: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Volume = styled.div`
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};
  transition: 0.2s;
`

const AudioPlayer = ({ src, width, height, playing, controls }) => {
  const [colorMode, setColorMode] = useColorMode()
  const playerRef = useRef(null)
  const containerRef = useRef(null)
  const volumeContainerRef = useRef(null)
  const volumeRef = useRef(null)
  const [volume, setVolume] = useState(1)
  const [isPlaying, setPlayingState] = useState(playing)
  const [seekbarPos, setSeekbarPos] = useState(0)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const isVolumeHover = useHoverDirty(volumeContainerRef)
  const isVolumeIconHover = useHoverDirty(volumeRef)

  const togglePlayingState = () => {
    setPlayingState(!isPlaying)
  }

  const handleVideoProgress = ({ played }) => {
    setSeekbarPos(played * 100)
    setCurrentTime(formatSeconds(playerRef.current.getCurrentTime()))
  }

  const handleSeekbarUpdate = value => {
    playerRef.current.seekTo(value / 100)
    setSeekbarPos(value)
  }

  const handleVolumeUpdate = value => {
    setVolume(value / 100)
  }

  const handleVideoReady = () => {
    setDuration(formatSeconds(playerRef.current.getDuration()))
  }

  const handleVideoEnded = () => {
    togglePlayingState()
  }

  return (
    <Container ref={containerRef} isDark={colorMode === "dark"}>
      <div onClick={togglePlayingState}>
        <ReactPlayer
          ref={playerRef}
          style={{ display: "flex" }}
          playing={isPlaying}
          url={src}
          width={width}
          height={height}
          progressInterval={50}
          volume={volume}
          onProgress={handleVideoProgress}
          onReady={handleVideoReady}
          onEnded={handleVideoEnded}
        />
      </div>
      {controls && (
        <Controls>
          <PlayButton onClick={togglePlayingState}>
            {isPlaying ? (
              <Icon src={pauseIcon} alt="Pause video" />
            ) : (
              <Icon src={playIcon} alt="Play video" />
            )}
          </PlayButton>
          <RangeContainer>
            <InputRange
              minValue={0}
              maxValue={100}
              value={seekbarPos}
              onChange={handleSeekbarUpdate}
              formatLabel={v => ``}
            />
          </RangeContainer>
          <TimeContainer>
            <Volume
              ref={volumeRef}
              isVisible={isVolumeHover || isVolumeIconHover}
            >
              <InputRange
                minValue={0}
                maxValue={100}
                value={volume * 100}
                onChange={handleVolumeUpdate}
                formatLabel={() => ``}
              />
            </Volume>
            <Time isHidden={isVolumeHover || isVolumeIconHover}>
              {currentTime}/{duration}
            </Time>
          </TimeContainer>
          <VolumeContainer ref={volumeContainerRef}>
            <Icon src={volumeIcon} alt="volume" />
          </VolumeContainer>
        </Controls>
      )}
    </Container>
  )
}

export default AudioPlayer

AudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  playing: PropTypes.bool,
  controls: PropTypes.bool,
}

AudioPlayer.defaultProps = {
  width: "100%",
  height: "auto",
  playing: false,
  controls: true,
}
