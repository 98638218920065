import React from "react"
import styled from "@xstyled/styled-components"
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share"

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ShareContainer = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: light;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
    visibility: visible;
  }
`

const SvgContainer = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 5px;

  &:hover ~ ${ShareContainer} {
    opacity: 1;
    visibility: visible;
  }
`

const URL = "https://compulsory.gallery"

const iconProps = {
  iconFillColor: "currentColor",
  bgStyle: {
    fill: "none",
  },
  size: 40,
}

const Share = () => {
  return (
    <Container>
      <SvgContainer>
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 125">
          <g transform="translate(0,-952.36218)">
            <path
              d="m 71,960.36218 c -7.7083,0 -14,6.2917 -14,14 0,1.4557 0.2091,2.8657 0.625,4.1875 l -19.4375,13.2812 c -2.4626,-2.1502 -5.6762,-3.4687 -9.1875,-3.4687 -7.7083,0 -14,6.2917 -14,14.00002 0,7.7083 6.2917,14 14,14 3.509,0 6.7255,-1.321 9.1875,-3.4688 l 19.4687,13.25 c -0.4237,1.333 -0.6562,2.749 -0.6562,4.2188 0,7.7083 6.2917,14 14,14 7.7083,0 14,-6.2917 14,-14 0,-7.7083 -6.2917,-14 -14,-14 -4.8177,0 -9.073,2.4623 -11.5938,6.1875 l -18.5937,-12.6875 c 1.3856,-2.1709 2.1875,-4.7442 2.1875,-7.5 0,-2.76172 -0.8278,-5.32622 -2.2188,-7.50002 l 18.625,-12.6875 c 2.5208,3.7252 6.7761,6.1875 11.5938,6.1875 7.7083,0 14,-6.2917 14,-14 0,-7.7083 -6.2917,-14 -14,-14 z m 0,4 c 5.5465,0 10,4.4535 10,10 0,5.5465 -4.4535,10 -10,10 -5.5465,0 -10,-4.4535 -10,-10 0,-5.5465 4.4535,-10 10,-10 z m -42,28 c 5.5465,0 10,4.4535 10,10.00002 0,5.5465 -4.4535,10 -10,10 -5.5465,0 -10,-4.4535 -10,-10 0,-5.54652 4.4535,-10.00002 10,-10.00002 z m 42,28.00002 c 5.5465,0 10,4.4534 10,10 0,5.5465 -4.4535,10 -10,10 -5.5465,0 -10,-4.4535 -10,-10 0,-5.5466 4.4535,-10 10,-10 z"
              fill="currentColor"
              fillOpacity="1"
              stroke="none"
              marker="none"
              visibility="visible"
              display="inline"
              overflow="visible"
            />
          </g>
        </svg>
      </SvgContainer>
      <ShareContainer>
        <EmailShareButton url={URL}>
          <EmailIcon {...iconProps} />
        </EmailShareButton>
        <FacebookShareButton url={URL}>
          <FacebookIcon {...iconProps} />
        </FacebookShareButton>
        <WhatsappShareButton url={URL}>
          <WhatsappIcon {...iconProps} size={33} />
        </WhatsappShareButton>
        <TwitterShareButton url={URL}>
          <TwitterIcon {...iconProps} />
        </TwitterShareButton>
      </ShareContainer>
    </Container>
  )
}

export default Share
