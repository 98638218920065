import React from "react"
import styled, { Box, useColorMode } from "@xstyled/styled-components"
import { RichText } from "prismic-reactjs"

import PageWrap from "../../atoms/PageWrap"
import Share from "../../atoms/Share"

const Supporter = styled.img`
  max-height: 55px;
  max-width: 55px;
  margin: 0 1;
  filter: ${p => (p.isInvert ? "invert(1)" : "invert(0)")};
`

const Italic = styled.box`
  font-style: italic;
`

const Supporters = ({ supporters }) => {
  const [colorMode, setColorMode] = useColorMode()
  return (
    <PageWrap>
      <Share />
      {supporters && supporters.length > 0 && (
        <Box mt={3} mb={8}>
          <Italic textAlign="center">Supported by</Italic>
          <Box mt={3} row justifyContent="center">
            {supporters.map((g, i) => {
              const img = g.supporter_image.url
              const alt = g.supporter_name && RichText.asText(g.supporter_name)
              const href = g.supporter_link && g.supporter_link.url

              return (
                <Box key={img}>
                  <a href={href}>
                    <Supporter
                      isInvert={colorMode === "dark"}
                      src={img}
                      alt={alt}
                    />
                  </a>
                </Box>
              )
            })}
          </Box>
        </Box>
      )}
    </PageWrap>
  )
}

export default Supporters
