import React from "react"

const InfosIcon = props => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23ZM12.1227 5.81C11.9327 5.62 11.7077 5.525 11.4477 5.525C11.1877 5.525 10.9627 5.62 10.7727 5.81C10.5827 6 10.4877 6.225 10.4877 6.485C10.4877 6.745 10.5827 6.97 10.7727 7.16C10.9627 7.35 11.1877 7.445 11.4477 7.445C11.7077 7.445 11.9327 7.35 12.1227 7.16C12.3127 6.97 12.4077 6.745 12.4077 6.485C12.4077 6.225 12.3127 6 12.1227 5.81ZM12.4827 16.46C12.3127 16.3 12.2277 16.015 12.2277 15.605V9.11C11.8177 9.25 11.2977 9.32 10.6677 9.32C10.3677 9.32 10.0577 9.305 9.73773 9.275V9.59C10.1577 9.59 10.4527 9.695 10.6227 9.905C10.7927 10.115 10.8777 10.45 10.8777 10.91V15.605C10.8777 16.015 10.7927 16.3 10.6227 16.46C10.4527 16.61 10.1577 16.685 9.73773 16.685V17C10.6577 16.96 11.2627 16.94 11.5527 16.94C11.8427 16.94 12.4477 16.96 13.3677 17V16.685C12.9477 16.685 12.6527 16.61 12.4827 16.46Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default InfosIcon
